import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/cards/cards/node_modules/holy-loader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/cards/cards/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/cards/cards/node_modules/react-async-stateful/esm/react/ReactHooks.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/cards/cards/packages/app/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/cards/cards/packages/app/src/components/app/InitialLayoutDetector/InitialLayoutDetector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/cards/cards/packages/app/src/components/app/Toasts/Toasts.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/cards/cards/packages/app/src/components/common/Link/Link.module.scss");
